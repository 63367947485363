<!-- NOTE Confirm form -->
<form [formGroup]="formGroup" (ngSubmit)="onClickSubmit()">

  <div class="mb-4">
    <p class="fs-15px" [innerHTML]="description"></p>
  </div>

  <div class="form-group mb-6 pb-4">
    <label class="form-label" for="serial">{{ label }}</label>
    <div class="form-control-wrap">
      <input type="text" formControlName="serial" class="form-control" value="" placeholder="Tres últimos caracteres"
        maxlength="3"
        (keydown)="clearInvalid()"
        [ngClass]="{ 'is-invalid' : invalidSerial && formGroup.controls.serial.touched }">
      <div class="invalid-feedback" *ngIf="formGroup.controls.serial.hasError('required')">
        {{ 'FIELD_REQUIRED' | translate }}
      </div>
      <div class="invalid-feedback" *ngIf="!formGroup.controls.serial.hasError('required') && invalidSerial">
        Los caracteres no coinciden
      </div>
    </div>
  </div>

  <!-- NOTE Submit -->
  <div class="d-flex justify-content-between">
    <button type="button" class="btn btn-light" (click)="onClickClose()">
      {{ 'BTN_CANCEL' | translate }}
    </button>
    <button type="submit" class="btn btn-danger ms-3" [disabled]="this.formGroup.invalid">
      {{ textButton }}
    </button>
  </div>
</form>
