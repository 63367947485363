// Angular modules
import { DatePipe, DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { EXPORTER_DATATYPE } from '@enums/exporter-datatype.enum';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ExporterHelper {

  public static getCollectionToExport(col: any[], itemExporter: any, translateService: TranslateService): object[] {

    const locale = translateService.currentLang || translateService.getDefaultLang() || 'es-ES';

    return col.map((item) => this.getExportableItem(item, itemExporter, translateService, locale));
  }

  /**
   *
   * @param itemToExport: Entity (normal object) with data
   * @param exporter: Object with an estructure like this:
   *        {
   *           code: {
   *             nameToShow: 'Código'
   *           },
   *           i18n: {
   *             nameToShow: 'Idioma'
   *           },
   *           country: {
   *             name: {
   *               nameToShow: 'País'
   *             }
   *           }
   *        }
   * @returns a plain object { property: value, ... }
   */
  public static getExportableItem(
    itemToExport: Record<string, any>,
    exporter: Record<string, any>,
    translateService: TranslateService,
    locale: string): object {

    const toret: Record<string, any> = {};

    // Función recursiva
    const processExport = (item: any, exportSchema: any, parentKey?: string) => {
      for (const property in exportSchema) {
        if (Object.prototype.hasOwnProperty.call(exportSchema, property)) {
          const config = exportSchema[property];

          // Caso de propiedad anidada
          if (typeof config === 'object' && !config.nameToShow) {
            processExport(item[property] || {}, config, property);
          }

          // Caso de propiedad simple con nameToShow
          if (config.nameToShow) {
            const propertyNameToExport = config.nameToShow;
            const propertyValueToExport = parentKey && item[parentKey] ? item[parentKey][property] : item[property] || '';
            const dataType = config.dataType || EXPORTER_DATATYPE.STRING;
            const pipeModifier = config.pipeModifier || undefined;

            toret[propertyNameToExport] = ExporterHelper.getItemToExportParsed(
              propertyValueToExport,
              dataType as EXPORTER_DATATYPE,
              pipeModifier,
              translateService,
              locale
            );
          }
        }
      }
    }

    // Llamada inicial a la función recursiva
    processExport(itemToExport, exporter);

    return toret;
  }

  private static getItemToExportParsed(
    valueToExport: any,
    dataType: EXPORTER_DATATYPE = EXPORTER_DATATYPE.STRING,
    pipeModifier: (string | undefined) = undefined,
    translateService: TranslateService,
    locale: string
  ) {

    // console.log(valueToExport, dataType, pipeModifier);

    switch (dataType) {

      case EXPORTER_DATATYPE.BOOLEAN:
        valueToExport = valueToExport ? 'Verdadero' : 'Falso';
        break;

      case EXPORTER_DATATYPE.DATE: {
        const datePipe = new DatePipe(locale);
        const datePipeModifier = pipeModifier || 'dd/MM/yyyy';
        valueToExport = datePipe.transform(valueToExport, datePipeModifier);
        break;
      }

      case EXPORTER_DATATYPE.NUMBER: {
        const decimalPipe: DecimalPipe = new DecimalPipe(locale);
        const numberPipeModifier = pipeModifier || '1.2-2'
        valueToExport = decimalPipe.transform(valueToExport, numberPipeModifier);
        break;
      }

      case EXPORTER_DATATYPE.AUTHORIZED:
        valueToExport = valueToExport ? 'Autorizada' : 'No autorizada';
        break;

    }

    return valueToExport;
  }


  // public static getExportableItem2(
  //   itemToExport: Record<string, any>,
  //   exporter: Record<string, any>,
  //   translateService: TranslateService): object {

  //   const toret: Record<string, any> = {};

  //   for (const property in exporter) {

  //       if (Object.prototype.hasOwnProperty.call(exporter, property)) {
  //           if (exporter[property].nameToShow) {
  //               if (Object.prototype.hasOwnProperty.call(itemToExport, property)) {
  //                   const propertyNameToExport = exporter[property]['nameToShow'];
  //                   const propertyValueToExport = itemToExport[property];
  //                   const dataType = exporter[property]['dataType'] || EXPORTER_DATATYPE.STRING;
  //                   const pipeModifier = exporter[property]['pipeModifier'] || undefined;

  //                   toret[propertyNameToExport] = this.getItemToExportParsed(propertyValueToExport, dataType as EXPORTER_DATATYPE, pipeModifier, translateService);
  //               }
  //           } else {
  //               const propertyThatIsAnObject = exporter[property];

  //               for (const p2 in propertyThatIsAnObject) {
  //                   if (Object.prototype.hasOwnProperty.call(propertyThatIsAnObject, p2) && propertyThatIsAnObject[p2].nameToShow) {
  //                       const propertyNameToExport = propertyThatIsAnObject[p2]['nameToShow'];
  //                       const propertyValueToExport = itemToExport[property] ? itemToExport[property][p2] : '';
  //                       const dataType = propertyThatIsAnObject[p2]['dataType'] || EXPORTER_DATATYPE.STRING;
  //                       const pipeModifier = propertyThatIsAnObject[p2]['pipeModifier'] || undefined;

  //                       toret[propertyNameToExport] = this.getItemToExportParsed(propertyValueToExport, dataType as EXPORTER_DATATYPE, pipeModifier, translateService);

  //                   } else {
  //                       const propertyThatIsAnObjectLevel2 = propertyThatIsAnObject[p2];

  //                       for (const p3 in propertyThatIsAnObjectLevel2) {
  //                           if (Object.prototype.hasOwnProperty.call(propertyThatIsAnObjectLevel2, p3) && propertyThatIsAnObjectLevel2[p3].nameToShow) {
  //                               const propertyNameToExport = propertyThatIsAnObjectLevel2[p3]['nameToShow'];
  //                               const propertyValueToExport = itemToExport[property] && itemToExport[property][p2] ? itemToExport[property][p2][p3] : '';
  //                               const dataType = propertyThatIsAnObjectLevel2[p3]['dataType'] || EXPORTER_DATATYPE.STRING;
  //                               const pipeModifier = propertyThatIsAnObjectLevel2[p3]['pipeModifier'] || undefined;

  //                               toret[propertyNameToExport] = this.getItemToExportParsed(propertyValueToExport, dataType as EXPORTER_DATATYPE, pipeModifier, translateService);
  //                           }
  //                       }
  //                   }
  //               }
  //           }
  //       }
  //   }

  //   return toret;
  // }

  // public static getExportableItem(itemToExport: any, exporter: any): object {
  //   let toret: any = {};

  //   for (let property in exporter) {

  //     // exists itemToExport[property].nameToShow => is a single property
  //     // like "code: { nameToShow: 'Código' }"
  //     if (exporter[property].nameToShow) {
  //       if (itemToExport.hasOwnProperty(property)) {
  //         // the entity also has that property
  //         const propertyNameToExport = exporter[property]['nameToShow'];
  //         const propertyValueToExport = itemToExport[property];

  //         toret[propertyNameToExport] = propertyValueToExport;
  //       }
  //     } else {
  //       // is an object property
  //       // like "country { name: { nameToShow: 'País' } }"

  //       const propertyThatIsAnObject = exporter[property];

  //       for (const p2 in propertyThatIsAnObject) {

  //         if (propertyThatIsAnObject[p2].nameToShow) {
  //           {
  //             const propertyNameToExport = propertyThatIsAnObject[p2]['nameToShow'];
  //             const propertyValueToExport = itemToExport[property] ? itemToExport[property][p2]: '';

  //             toret[propertyNameToExport] = propertyValueToExport;
  //           }
  //         }

  //       }

  //     }
  //   }

  //   return toret;
  // }
}
