export enum EXPORTER_DATATYPE {

  STRING = 'string',
  DATE = 'date',
  HOUR_AND_MINUTES = 'HourAndMinutes',
  BOOLEAN = 'boolean',
  NUMBER = 'number',
  AUTHORIZED = 'authorized',

}
