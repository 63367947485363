// Angular Modules
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

// External Modules
import { catchError, map, throwError } from 'rxjs';

// Models
import { QueryResults } from '@models/api-request.model';
import { QueryParameters } from '@models/index';


const httpOptions = {
  observe: 'response' as 'body', //can not be injected in Interceptor.
  params: {}
};

@Injectable({
  providedIn: 'root'
})
export class HttpQueryUtils {

  constructor(private http: HttpClient) { }

  /**
    * Executes a query
    *
    * @param url Is the URL to consume an endpoint
    * @param queryParameters This parameter indicates pageNumber, order, etc.
    * returns an Observable<PostResult>
    */
  public executeQuery<T>( url: string, queryParameters: QueryParameters) {

    httpOptions.params = this.prepareParams(queryParameters);
    console.log('🚀 ~ file: http-query-utils.service.ts:79 ~ HttpQueryUtils ~ url:', url);

    const data$ = this.http.get<T>(url, httpOptions).pipe(
      map((resp: any) => {

        let itemsReponse = [];
        if (Array.isArray(resp.body)) {
          itemsReponse = resp.body;
        } else if (resp.body !== null) {
          itemsReponse = [resp.body]
        } else {
          itemsReponse = []
        }


        const data: QueryResults<T> = {
          items:  itemsReponse, // posts are always an array (even if one result)
          totalItems: resp.headers.get('x-allsnsd-total'),
          totalPages: resp.headers.get('x-allsnsd-totalpages')
        };

        this.doParseData(data);

        return data;
      }),

      catchError((err) => {
        return throwError(() => err);
      })
    );

    return data$;
  }


  /**
    * Executes a query
    *
    * @param url Is the URL to consume an endpoint
    * @param queryParameters This parameter indicates pageNumber, order, etc.
    * returns an Observable<PostResult>
    */
  public executeQueryByFilter<T>(url: string, queryParameters: QueryParameters) {

    const params = this.prepareParams(queryParameters);

    const httpOptions: any = {
      observe: 'response' as 'body', //can not be injected in Interceptor.
      params: params,
    };

    const requestBody = this.getSpecificFilterFromQueryParameters(queryParameters);

    // requestBody = {
    //   "fromDate": "300424",
    //   "toDate": "300424"
    // }
    // httpOptions.fromObject = requestBody;


    const data$ = this.http.post<T>(url, requestBody, httpOptions).pipe(
      map((resp: any) => {

        let itemsReponse = [];
        if (Array.isArray(resp.body)) {
          itemsReponse = resp.body;
        } else if (resp.body !== null) {
          itemsReponse = [resp.body]
        } else {
          itemsReponse = []
        }


        const data: QueryResults<T> = {
          items: itemsReponse, // posts are always an array (even if one result)
          totalItems: resp.headers.get('x-allsnsd-total'),
          totalPages: resp.headers.get('x-allsnsd-totalpages')
        };

        this.doParseData(data);

        return data;
      }),

      catchError((err) => {
        return throwError(() => err);
      })
    );

    return data$;
  }


  private doParseData<T>(data: T) {
    return data;
  }




  // flats queryParameter getting an object like this: { property: value }. This object will be passed to request
  // it's needed because original queryParameters is a nested object like: { pageSize: 1, filter: { name: value, code: value } }
  private prepareParams(queryParameters: QueryParameters) {

    const queryParametersCloned = { ...queryParameters, ...queryParameters.filterByParams };
    delete queryParametersCloned.filterByParams;
    delete queryParametersCloned.filterBySpecificFilter;


    return queryParametersCloned;

  }


  private getSpecificFilterFromQueryParameters(queryParameters: QueryParameters): any {

    let toret = {}

    if (queryParameters.filterBySpecificFilter?.filterValue) {

      toret = queryParameters.filterBySpecificFilter?.filterValue
    }


    return toret || {};
  }


}
