{
  "name": "lockbin-backend-portal",
  "version": "0.3.3",
  "scripts": {
    "ng": "ng",
    "start": "ng serve -o --host backend.lockbin.local --ssl --ssl-key lockbin.es.key --ssl-cert lockbin.es.crt",
    "start:pre": "ng serve -o --host backend.lockbin.dev --ssl --ssl-key lockbin.es.key --ssl-cert lockbin.es.crt --configuration preproduction",
    "start:prod": "ng serve -o --host backend.lockbin.es --ssl --ssl-key lockbin.es.key --ssl-cert lockbin.es.crt --port 443",
    "build": "ng build",
    "build:pre": "ng build --configuration preproduction",
    "build:prod": "ng build --configuration production",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "lint": "ng lint"
  },
  "private": true,
  "engines": {
    "npm": ">=10.0.0",
    "node": ">=20.0.0"
  },
  "dependencies": {
    "@angular/animations": "^17.3.0",
    "@angular/common": "^17.3.0",
    "@angular/compiler": "^17.3.0",
    "@angular/core": "^17.3.0",
    "@angular/forms": "^17.3.0",
    "@angular/google-maps": "^17.3.8",
    "@angular/platform-browser": "^17.3.0",
    "@angular/platform-browser-dynamic": "^17.3.0",
    "@angular/router": "^17.3.0",
    "@googlemaps/js-api-loader": "^1.16.8",
    "@googlemaps/markerclusterer": "^2.5.3",
    "@ng-bootstrap/ng-bootstrap": "^16.0.0",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "@popperjs/core": "^2.11.8",
    "angular-svg-icon": "^17.0.0",
    "bootstrap": "5.2.3",
    "chart.js": "^4.4.2",
    "chartjs-plugin-datalabels": "^2.2.0",
    "exceljs": "^4.4.0",
    "file-saver": "^2.0.5",
    "ngx-logger": "^5.0.12",
    "rxjs": "~7.8.0",
    "simplebar-angular": "^3.2.4",
    "sweetalert2": "^11.6.13",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.3.4",
    "@angular-eslint/builder": "17.3.0",
    "@angular-eslint/eslint-plugin": "17.3.0",
    "@angular-eslint/eslint-plugin-template": "17.3.0",
    "@angular-eslint/schematics": "17.3.0",
    "@angular-eslint/template-parser": "17.3.0",
    "@angular/cli": "^17.3.4",
    "@angular/compiler-cli": "^17.3.0",
    "@angular/localize": "^17.3.0",
    "@types/file-saver": "^2.0.7",
    "@types/google.maps": "^3.55.9",
    "@types/jasmine": "~5.1.0",
    "@types/node": "^20.12.7",
    "@typescript-eslint/eslint-plugin": "7.2.0",
    "@typescript-eslint/parser": "7.2.0",
    "eslint": "^8.57.0",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "typescript": "~5.4.2"
  }
}
