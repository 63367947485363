
export enum AUTHORIZATION_TYPE {

  NONE      = -1,

  AUTHORIZATION_BY_MIFARE_KEY_ALLWAYS = 0,
  AUTHORIZATION_BY_PUBLIC_KEY_DISCARD_BLACKLIST = 1,
  AUTHORIZATION_BY_PUBLIC_KEY_ONLY_WHITELIST = 2,
  AUTHORIZATION_BY_PRIVATE_KEY = 3,
  AUTHORIZATION_BY_PRIVATE_KEY_DISCARD_BLACKLIST = 4,
  AUTHORIZATION_BY_PRIVATE_KEY_ONLY_WHITELIST = 5,

}

export function getAuthorizationTypes(): { value: number, name: string }[] {
  return Object.keys(AUTHORIZATION_TYPE)
    .filter((key: any) => !isNaN(Number(AUTHORIZATION_TYPE[key])))
    .map((key: any) => {
      return { value: Number(AUTHORIZATION_TYPE[key]), name: key };
    });
}

export function getAuthorizationTypeByValue(valueToSeach: number, arr: { value: number, name: string }[]): string | undefined {

  let toret: string | undefined = '';

  toret = arr.find( item => item.value === valueToSeach )?.name;

  return toret ? toret : '-';
}
