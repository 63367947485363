
export enum DEVICE_MODE_TYPE {

  STORE = 0, // ESTE MODO ESTÁ DEPRECATED
  SETUP = 1, // En modo setup, abierta la comunicación
  STREET = 2, // Funcionamiento normal
  SURVIVAL = 3, // Modo supervicencia

}

export function getDeviceModeTypes(): { value: number, name: string }[] {

  return Object.keys(DEVICE_MODE_TYPE)
    .filter((key: any) => !isNaN(Number(DEVICE_MODE_TYPE[key])))
    .map((key: any) => {
      return { value: Number(DEVICE_MODE_TYPE[key]), name: 'DEVICE_MODE_TYPE_' + key };
    });

}
