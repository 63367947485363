// Angular modules
import { Component } from '@angular/core';
import { OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lockbin-form-confirm',
  templateUrl: './form-confirm.component.html',
  styleUrls: ['./form-confirm.component.scss'],
})
export class FormConfirmComponent implements OnInit {
  @Input() data: any;
  @Output() submitData: EventEmitter<boolean> = new EventEmitter();
  @Output() submitClose: EventEmitter<null> = new EventEmitter();

  formGroup!: FormGroup<{
    serial: FormControl<string>;
  }>;

  invalidSerial = false;

  description = 'Para desvincular el equipo es necesario una verificación. Introduce los <strong class="fw-bold">tres últimos caracteres</strong> del número de contenedor.';
  label = 'Introduce el número de contenedor:';
  textButton = '';
  dataValidate = '';

  constructor(private translateService: TranslateService) {}

  public ngOnInit(): void {

    this.textButton = this.translateService.instant('BTN_DELETE');

    if (this.data) {

      console.log(this.data);

      const { dataValidate, description, label, textButton } = this.data;

      if (dataValidate) this.dataValidate = dataValidate;
      if (description) this.description = description;
      if (label) this.label = label;
      if (textButton) this.textButton = textButton;

    }

    this.formGroup = new FormGroup({
      serial: new FormControl<string>(
        {
          value: '',
          disabled: false,
        },
        { validators: [Validators.required], nonNullable: true }
      ),
    });
  }

  // -------------------------------------------------------------------------------
  // NOTE Action -------------------------------------------------------------------
  // -------------------------------------------------------------------------------

  clearInvalid() {
    this.invalidSerial = false;
  }

  public async onClickSubmit(): Promise<void> {

    this.invalidSerial = false;

    if (this.dataValidate.length > 0 && this.formGroup.controls.serial.value.slice(-3) === this.dataValidate) {
      this.submitData.emit(true);
    } else {
      this.invalidSerial = true;
    }

  }

  public onClickClose(): void {
    this.submitClose.emit();
  }
}
