
// TODO: Este fichero hay que cambiarlo de sitio


export class DataList<T> {
  name: string = '';
  useCheckbox: boolean = false;
  header: DataListHeader = new DataListHeader();
  data: DataListItems<T> = new DataListItems();
  rowOperations: Array<DataListOperation>= [];
  bulkOperations: Array<DataListOperation>= [];
  useSearch: boolean = true;
  searchPlaceholder: string = 'Find........';
  maxNumberRecordsToExport: number = 2;

  public getCssClassColumn(alias: string) {
    const headerItem = this.header.headers.find(
      (columnHeader: DataListHeaderItem) => {
        return columnHeader.alias === alias;
      }
    );

    let cssClasses = [];

    if (headerItem?.visibleSize) {
      cssClasses.push('tb-col-' + headerItem.visibleSize);
    }

    if (headerItem?.cssClass) {
      cssClasses = Array.isArray(headerItem?.cssClass) ? [...cssClasses, ...headerItem.cssClass] : [...cssClasses, headerItem.cssClass];
    }

    return cssClasses.join(' ');
  }

}




export class DataListHeader {
  canToggleItems: boolean = true;
  pagination: DataListPagination = new DataListPagination();
  headers: Array<DataListHeaderItem> = [];
}

export class DataListPagination {
  constructor(
    public mode: PaginationMode = PaginationMode.Buttons,
    public itemsPerPage: number = 5,
    public itemsPerPageRange: Array<number> = [1, 2, 5, 10, 25, 50],
    public sortOrder: string = "DESC"
    ) { }
}

export class DataListHeaderItem {
  alias: string = '';
  title: string = '';
  sortable: boolean = false;
  visibleSize?: ResponsiveBreakpoints;
  visible: boolean = true; // TODO: Implementar esta funcionalidad
  cssClass?: any;
  html?: string = '';
}

export class DataListItems<T> {
  items: Array<T>= [];
}

export class DataListItem<T> {
  id: any;
  alias?: string = '';
  content: any;
}

export interface DataListOperation {
  alias: DataListRowOperationAlias;
  icon?: string;
  title: string;
}

export interface DataListEventRowOperation {
  operation: string;
  item?: any;
}


export enum ResponsiveBreakpoints {
  ExtraSmall = 'xs',
  Small = 'sm',
  MediumSmall = 'mb', // Solo se usa en datatables
  Medium = 'md',
  Large = 'lg',
  ExtraLarge = 'xl',
  ExtraExtraLarge = 'xxl',
}

export enum PaginationMode {
  Buttons = 'buttons'
}

export enum StatusCssClass {
  Sucess = 'success',
  Warning = 'warning',
  Danger = 'danger'
}

export enum PaginationSortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum DataListRowOperationAlias {

  ViewDetails = 'view-details',
  QuickView = 'quick-details',
  Disable = 'disable',
  Delete = 'delete',
  Suspend = 'suspend',
  Edit = 'edit',
  Divider = 'divider',
  Email = 'email',
  ChangeGroup = 'group',


  Validate = 'validate',
  RequireRevision = 'require-revision',
  MalFunction = 'malfunction',
  Match = 'match',
  ApplyConfigurationProfile = 'apply-configuration-profile',
  ApplyFirmware = 'apply-firmware',
  ChangeInstallationState = 'change-installation-state',
  SendReset = 'send-reset',

  Relocate = 'relocate'

}
